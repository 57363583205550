import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'connexion', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'connexion', loadChildren: './pages/connexion/connexion.module#ConnexionPageModule' },
  { path: 'journee', loadChildren: './pages/journee/journee.module#JourneePageModule' },
  { path: 'incident', loadChildren: './pages/incident/incident.module#IncidentPageModule' },
  { path: 'chantier', loadChildren: './pages/chantier/chantier.module#ChantierPageModule' },
  { path: 'motdepasse', loadChildren: './pages/motdepasse/motdepasse.module#MotdepassePageModule' },
  { path: 'planning', loadChildren: './pages/planning/planning.module#PlanningPageModule' },
  { path: 'liste-chantiers', loadChildren: './pages/liste-chantiers/liste-chantiers.module#ListeChantiersPageModule' },
  { path: 'recap-semaine', loadChildren: './pages/recap-semaine/recap-semaine.module#RecapSemainePageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
