import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { LocalStorageService } from '../app/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private alertCtrl: AlertController,
    private storage: Storage,
    // private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  deconnexion() {
    this.alertCtrl.create({
      message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      buttons: [
        {
          text: 'Oui',
          role: 'cancel',
          handler: data => {
            this.storage.set('token', null);
            this.router.navigateByUrl('/connexion');
          }
        },
        {
          text: 'Retour'
        },
      ]
    }).then((res) => {
      res.present();
    });

  }
}
